@import "@/assets/scss/var.scss";
.search-ret {
	// background: #fff;
	.search-tips {
		padding: 8px 12px;
		color: #f56723;
		line-height: 1.5;
		background-color: #fff7cc;
        >.bold{
            // font-weight: bold;
        }
	}
}
